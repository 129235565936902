<template>
  <div class="container text-center mt-5">
    <!-- Logo -->
    <img src="@/assets/Kopi_Kenangan.png" alt="Logo" class="mb-3 logo" />

    <h1>Steps Counts Distance</h1>
    
    <!-- Error Message -->
    <div v-if="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>

    <!-- Location Info -->
    <div v-if="latitude && longitude" class="card p-3 mt-3">
      <p><strong>Latitude:</strong> {{ latitude }}</p>
      <p><strong>Longitude:</strong> {{ longitude }}</p>
      <p><strong>Distance from start:</strong> {{ distance }} kilometers</p>
      <p><strong>Estimated Steps:</strong> {{ steps }}</p>
      <p><strong>Per Step Distance:</strong> {{ stepLength }} kilometers</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const latitude = ref(null);
const longitude = ref(null);
const errorMessage = ref(null);
const distance = ref(0);
const steps = ref(0);
const stepLength = 0.00078;

let watchId = null;
let startCoords = { latitude: null, longitude: null };

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371000; // Radius of Earth in meters
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceInMeters = R * c; // Distance in meters
  return distanceInMeters / 1000; // Convert to kilometers
};

const handlePositionUpdate = (position) => {
  const { latitude: lat, longitude: lon } = position.coords;

  // Update the reactive variables
  latitude.value = lat;
  longitude.value = lon;

  if (startCoords.latitude === null || startCoords.longitude === null) {
    startCoords = { latitude: lat, longitude: lon };
  } else {
    distance.value = calculateDistance(
      startCoords.latitude,
      startCoords.longitude,
      lat,
      lon
    ).toFixed(2); // Limit to 2 decimal places for better readability

    // Calculate estimated steps based on the distance
    steps.value = Math.floor(distance.value / stepLength);
  }

  errorMessage.value = null;
};

const getLocation = () => {
  if (navigator.geolocation) {
    watchId = navigator.geolocation.watchPosition(
      handlePositionUpdate,
      (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            errorMessage.value = 'User denied the request for Geolocation.';
            break;
          case error.POSITION_UNAVAILABLE:
            errorMessage.value = 'Location information is unavailable.';
            break;
          case error.TIMEOUT:
            errorMessage.value = 'The request to get user location timed out.';
            break;
          case error.UNKNOWN_ERROR:
            errorMessage.value = 'An unknown error occurred.';
            break;
        }
      },
      {
        enableHighAccuracy: true, // High accuracy for real-time updates
        timeout: Infinity, // No timeout
        maximumAge: 0, // No caching, always get the latest position
      }
    );
  } else {
    errorMessage.value = 'Geolocation is not supported by this browser.';
  }
};

// Start tracking when the component is mounted
onMounted(() => {
  getLocation();
});

// Cleanup on unmount
onUnmounted(() => {
  if (watchId !== null) {
    navigator.geolocation.clearWatch(watchId);
  }
});
</script>

<style scoped>
/* Add your styles here */
</style>
